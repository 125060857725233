<template>
  <el-card class="box-card">
    <el-col :span="12">
      <el-button type="warning" @click="handleEdit" size="small">设置奖励</el-button>
    </el-col>
    <el-col :span="12" style="text-align: right">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="活动:">
          <el-select
              v-model="queryForm.activity"
              clearable
              placeholder="请选择用户"
              style="width: 100%"
              size="small"
          >
            <el-option
                v-for="item in apiActivity"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              v-model="query_time"
              size="small"
              clearable
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="getQueryTime"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
          <!--            <el-button @click="rechargePackageSettings" type="info" size="small">返回活动列表</el-button>-->
        </el-form-item>
      </el-form>
    </el-col>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="create_at"
          label="日期">
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="user_id"
          label="会员">
      </el-table-column>
      <el-table-column
          align="center"
          label="优惠券">
        <template #default="{row}">
          <span v-for="(item,index) in row.couponName" :key="index" class="site">
            {{ item.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="money"
          label="余额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="integral"
          label="积分">
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        style="text-align: center;"
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetConsumerIncentives, apiGetConsumerInfo, apiGetConsumerUsers} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      query_time: [],
      activeName: '1',
      apiActivity: [],
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        begin_time: '',
        end_time: '',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
    this.requestActivity()
  },
  methods: {
    getQueryTime(event) {
      if (event.length == 2) {
        this.queryForm.begin_time = event[0]
        this.queryForm.end_time = event[1]
      } else {
        this.queryForm.begin_time = ''
        this.queryForm.end_time = ''
      }
    },
    // 获取活动
    async requestActivity() {
      const {data} = await select()
      this.apiActivity = data
    },
    rechargePackageSettings() {
      this.$router.push({
        path: 'rechargePackageSettings',
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'consumptionReward/revise',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'consumptionReward/revise',
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetConsumerUsers(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
