<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="标题" prop="title">
        <el-input v-model="form.title" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="内容" prop="content">
        <el-input v-model="form.content" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否启用" prop="state">
        <el-switch
            v-model="form.state"
            size="small"
            active-color="#13ce66"
            active-text="开启"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="禁用"
            inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'Edit',
    data() {
      return {
        form: {
          title: '',
          content: '',
          state: '1',
        },
        rules: {
          title: [
            { required: true, trigger: 'blur', message: '请输入标题' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '设置'
        } else {
          this.title = '修改'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { code, message } = await doEdit(this.form)
            if (code == '200') {
              this.$message.success(message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(message)
              this.$emit('fetch-data')
              this.close()
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
